import LayoutA7 from "../../components/layout-a7"
import React from "react"
import EnrollInfoDetail from '../../components/enroll-info-detail'

export default class A7EnrollInfoDetail extends React.Component {
  render() {
    const color=this.props.pageContext.element.color
    return(
      <LayoutA7>
        <EnrollInfoDetail  color={color}/>
      </LayoutA7>
    )
  }
}